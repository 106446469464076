const List = ({ highlights }) => {
  return (
    <ul className="information__list">
      {highlights.map((item, index) => {
        return (
          <li key={index} className="information__listItem">
            {item}
          </li>
        );
      })}
    </ul>
  );
};

export default List;
