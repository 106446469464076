import Information from "./information.jsx";
import Posts from "./posts.jsx";

const ContentRow = ({ mainPageArticles, donationBoxData, landingPageData }) => {
  const renderRow = () => {
    if (landingPageData) {
      return (
        <>
          <Information landingPageData={landingPageData} />
          <Information donationBoxData={donationBoxData} />
        </>
      );
    } else {
      return (
        <>
          <Posts
            sectionHeadline="Najnovije sačuvano"
            mainPageArticles={mainPageArticles}
            startNumber={0}
            articlesToShow={4}
          />
          <Posts
            mainPageArticles={mainPageArticles}
            startNumber={4}
            articlesToShow={4}
          />
        </>
      );
    }
  };

  return <section className="content__featureOverview">{renderRow()}</section>;
};

export default ContentRow;
