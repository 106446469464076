import List from "./list.jsx";
import DonationButton from "./donationButton.jsx";

const Information = ({
  landingPageData,
  footerData,
  donationBoxData,
  feedbackData,
}) => {
  if (landingPageData) {
    return landingPageData.map(({ title, paragraph, highlights }, index) => (
      <article className="information" key={index}>
        <h2 className="information__header">{title}</h2>
        {paragraph.map((item, idx) => (
          <p key={idx} className="information__paragraph">
            {item}
          </p>
        ))}
        {highlights !== false && <List highlights={highlights} />}
      </article>
    ));
  }

  if (donationBoxData) {
    return donationBoxData.map(({ title, paragraph, highlights }, index) => (
      <article key={index} className="information">
        <h2 className="information__header">{title}</h2>
        {paragraph.map((item, paragraphIndex) => (
          <p key={paragraphIndex} className="information__paragraph">
            {item}
          </p>
        ))}
        {highlights !== false ? <List highlights={highlights} /> : null}
        <DonationButton />
      </article>
    ));
  }

  if (footerData) {
    // ?NEEDS TO BE IN IF STATEMENT SINCE DATA LOADS TOO FAST
    return footerData.map(({ title, paragraph, highlights }, index) => (
      <article key={index} className="information">
        <h2 className="information__header">{title}</h2>
        {paragraph.map((item, paragraphIndex) => (
          <p key={paragraphIndex} className="information__paragraph">
            {item}
          </p>
        ))}
        {highlights !== false ? <List highlights={highlights} /> : null}
      </article>
    ));
  }
  if (feedbackData) {
    // ?NEEDS TO BE IN IF STATEMENT SINCE DATA LOADS TOO FAST
    return feedbackData.map(({ title, paragraph, highlights }, index) => (
      <article key={index} className="information">
        <h2 className="information__header">{title}</h2>
        {paragraph.map((item, paragraphIndex) => (
          <p key={paragraphIndex} className="information__paragraph">
            {item}
          </p>
        ))}
        {highlights !== false ? <List highlights={highlights} /> : null}
        <DonationButton feedback={true} />
      </article>
    ));
  }
};

export default Information;
