import React, { useEffect, useState } from "react";

const Posts = ({
  mainPageArticles,
  sectionHeadline,
  startNumber,
  articlesToShow,
}) => {
  const [browserWidth, setBrowserWidth] = useState();
  const [headlineLength, setHeadlineLength] = useState();
  const [paragraphLength, setParagraphLength] = useState();

  let newHeadlineLength;
  let newParagraphLength;

  const handleResize = () => {
    setBrowserWidth(window.innerWidth);
  };

  const determineCharacterLength = () => {
    if (browserWidth <= 480) {
      newHeadlineLength = 60;
    } else if (browserWidth <= 530) {
      newHeadlineLength = 90;
    } else if (browserWidth <= 600) {
      newHeadlineLength = 45;
      newParagraphLength = 55;
    } else if (browserWidth <= 660) {
      newHeadlineLength = 45;
      newParagraphLength = 75;
    } else if (browserWidth <= 780) {
      newHeadlineLength = 60;
      newParagraphLength = 90;
    } else if (browserWidth <= 840) {
      newHeadlineLength = 100;
      newParagraphLength = 120;
    } else if (browserWidth <= 1150) {
      newHeadlineLength = 115;
      newParagraphLength = 135;
    } else if (browserWidth < 1170) {
      newHeadlineLength = 45;
      newParagraphLength = 65;
    } else if (browserWidth < 1400) {
      newHeadlineLength = 45;
      newParagraphLength = 75;
    } else if (browserWidth < 1650) {
      newHeadlineLength = 60;
      newParagraphLength = 90;
    } else if (browserWidth < 1800) {
      newHeadlineLength = 100;
      newParagraphLength = 120;
    } else if (browserWidth > 1800) {
      newHeadlineLength = 115;
      newParagraphLength = 135;
    }
    return { newHeadlineLength, newParagraphLength };
  };

  useEffect(() => {
    handleResize();

    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    const { newHeadlineLength, newParagraphLength } =
      determineCharacterLength();
    setHeadlineLength(newHeadlineLength);
    setParagraphLength(newParagraphLength);
  }, [browserWidth]);

  const adjustHeaderParagraphSize = (
    headline,
    headlineLength,
    paragraph,
    paragraphLength
  ) => {
    //? regex to find whitespaces
    const regex = /^\s+|\s+$/g;
    if (headline.length > headlineLength) {
      //? if headline has whitespaces at beginning or end, remove them
      if (regex.test(headline)) {
        headline = headline.replace(/\s+/g, " ").trim();
      }
      headline = headline.slice(0, headlineLength - 3) + "...";
    }
    if (paragraph.length > paragraphLength) {
      //? if paragraph has whitespaces at beginning or end, remove them
      if (regex.test(paragraph)) {
        paragraph = paragraph.replace(/\s+/g, " ").trim();
      }

      paragraph = paragraph.slice(0, paragraphLength - 3) + "...";
    }
    return { headline, paragraph };
  };

  function createSourceLink(inputString) {
    // Check if inputString is null, undefined, or empty
    if (!inputString) return null;

    // Split the string by the colon to extract the name and URL

    // Check if both name and url exist after the split

    // Split the string by the colon to extract the name and URL
    const [name, url] = inputString.split(">");

    if (!name || !url) return null;

    return (
      <span className="photo_details">
        <a
          className="photo_link"
          href={url + "?utm_source=sacuvaj&utm_medium=referral"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </a>
      </span>
    );
  }

  if (mainPageArticles) {
    return (
      <article className="information">
        <h2 className="information__header">{sectionHeadline}</h2>
        {mainPageArticles.map((article, index) => {
          if (index >= startNumber && index < articlesToShow + startNumber) {
            const slug = article.slug;
            const uniqueID = article.unique_id;
            const articleURL = uniqueID + "/" + slug;
            // const imgItem = article.data.find((item) => item.tag === "img");
            let imgItem = "";
            let imgDetails = null;

            const imgItemIndex = article.data.findIndex(
              (item) => item.tag === "img"
            );

            if (imgItemIndex !== -1) {
              imgItem = article.data[imgItemIndex];

              // Check if the next element is an "img_details" and assign it
              if (
                article.data[imgItemIndex + 1] &&
                article.data[imgItemIndex + 1].tag === "img_details"
              ) {
                imgDetails = article.data[imgItemIndex + 1].text;
              }
            }
            let thumbnail;

            if (imgItem && imgItem.text) {
              thumbnail = imgItem.text;
            } else {
              thumbnail = "https://picsum.photos/1900/800";
            }
            let headline = article.data.find((item) => item.tag === "h1").text;
            let paragraph = article.data.find((item) => item.tag === "p").text;

            ({ headline, paragraph } = adjustHeaderParagraphSize(
              headline,
              headlineLength,
              paragraph,
              paragraphLength
            ));

            return (
              <React.Fragment key={index}>
                <article className="information__post">
                  <div className="information__post__holder">
                    <a className="information__post__link" href={articleURL}>
                      <div
                        className="information__thumbnailHolder"
                        style={{
                          backgroundImage: `url(${thumbnail})`,
                          backgroundRepeat: "no-repeat",
                          backgroundSize: "cover",
                        }}
                      ></div>
                    </a>
                    {createSourceLink(imgDetails)}
                  </div>
                  <section className="postInfo">
                    <a href={articleURL}>
                      <h3 className="postInfo__header">{headline}</h3>
                    </a>
                    <p className="postInfo__paragraph">{paragraph}</p>
                  </section>
                </article>
              </React.Fragment>
            );
          }
        })}
      </article>
    );
  }
};

export default Posts;
